import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Alert, Card, Col, Form, Row, Button, FormControl } from 'react-bootstrap'
import { CloudUpload, InfoCircle } from 'react-bootstrap-icons'
import CSVReader from 'react-csv-reader'
import { toast } from 'react-toastify'
import moment from 'moment'
import _ from 'lodash'

import { CircularProgress } from '@material-ui/core'

import { ContentWrapper, SimpleInputGroup, Table, Modal, ErrorModal } from "../../components";
import { ApiRequest, exportData } from '../../helpers'
import { API_END_POINTS } from '../../config'
import { LambdaClient, InvokeCommand } from "@aws-sdk/client-lambda"; // ES Modules import

const config = {
    region: 'ap-southeast-1',
    credentials: {
        accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
        secretAccessKey: process.env.REACT_APP_AWS_ACCESS_SECRET
    },
};

// Create a Lambda client
const client = new LambdaClient(config);

const TransactionQuery = ({ routeOpt = [], user, vehicleOpt = [], driverOpt = [], onSubmitQuery = () => { }, onClearQuery = () => { } }) => {
    const [route, setRoute] = useState('All')
    const [amPm, setAmPm] = useState('All')
    const [weekendWeekday, setWeekendWeekday] = useState('All')
    const [selectFromDate, setFromDate] = useState(null)
    const [selectToDate, setToDate] = useState(null)
    const [vehicle, setVehicle] = useState(null)
    const [driver, setDriver] = useState(null)

    const [canFilter, setCanFilter] = useState(false);

    const handleSubmitQuery = (e) => {
        e.preventDefault();

        const query = { route, selectFromDate, selectToDate }

        if (!selectFromDate || selectFromDate === 'null') return toast.error("Please select the month to search");
        if (!route || route === 'null') return toast.error("Please select a route");
        onSubmitQuery(query)
    }

    const handleClear = () => {
        document.getElementById('date-from').value = ''
        setFromDate(null)
        setToDate(null)
        setRoute(null)
        setWeekendWeekday('All')
        onClearQuery()
    }

    const setperiod = async (e) => {
        const mon = e.currentTarget.value;
        const start = await moment(mon)
            .startOf("month")
            .format("YYYY-MM-DD 00:00:00");
        const end = await moment(mon).endOf("month").format("YYYY-MM-DD 23:59:59");
        await setFromDate(start);
        setToDate(end);
        setCanFilter(true);
    };

    return (
        <Card>
            <Card.Body >
                <h5>Search</h5>
                <Row>
                    <Col lg={4} md={12} sm={12} ></Col>
                    <Col lg={8} md={12} sm={12} >
                        <Form onSubmit={handleSubmitQuery} className='text-right' >
                            <SimpleInputGroup labelWidth={10} preappendText="Month:">
                                <Form.Control
                                    id="date-from"
                                    max={moment().format("YYYY-MM")}
                                    onChange={setperiod}
                                    min={
                                        user.userType !== "SUPER_ADMIN"
                                            ? moment().subtract(3, "months").format("YYYY-MM")
                                            : moment().subtract(12, "months").format("YYYY-MM")
                                    }
                                    type="month"
                                />
                            </SimpleInputGroup>
                            <Button className='ml-2' onClick={handleClear} variant='warning' >Clear</Button>
                            <Button className='ml-2' type='submit' >Search</Button>
                        </Form>
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    )
}

const MyBasReportByNetworkPage = ({ user }) => {

    const [loading, setLoading] = useState(false);
    const [transaction, setTransaction] = useState(null)
    const [routesArr, setRoutesArr] = useState(null)

    const [isNetworkReport, setIsNetworkReport] = useState(false);
    const [errorModal, setErrorModal] = useState(false);

    const [mainApiData, setMainApiData] = useState(null)

    const [driverList, vehicleList] = useMemo(() => {
        if (!transaction) return []
        const drivers = []
        const vehicles = []
        const copy = transaction.reverse() // not because i want this in reverse, but i just want to make a copy without temper the original value
        copy.forEach(({ driverName, vehicleRegistrationNumber }) => {
            drivers.push(driverName)
            vehicles.push(vehicleRegistrationNumber)
        })

        return [_.uniq(drivers), _.uniq(vehicles)]
    }, [transaction])

    /* *********
    API Handler
    ********** */
    const handleGetAllRoute = () => {
        ApiRequest.fetch({
            method: 'get',
            url: `${API_END_POINTS.ROUTE_GET_ALL}`,
            params: { showNotActive: false }
        }).then(async (data) => {
            await setRoutesArr(data)
        }).catch(e => { })
    }

    /* *******
    useEffect
    ******** */
    useEffect(() => {
        // handleGetTransactionHistory()
        handleGetAllRoute();
    }, []);

    const handleClearQuery = useCallback(() => {
        handleGetAllRoute();
    }, []);

    const handleFilterTransactionData = useCallback(async ({ route, selectFromDate, selectToDate, }) => {
        setLoading(true);

        if (route == 'All') setIsNetworkReport(true);

        if (process.env.REACT_APP_NODE_ENV != 'production') {
            console.log('local data')
            const data = await ApiRequest.fetch({
                method: "get",
                url: `${API_END_POINTS.SBST_COLLECTION}`,
                params: {
                    timestamp: new Date(),
                    from: selectFromDate,
                    to: selectToDate,
                    routeShortName: route
                },
            });

            // console.log(data);
            setMainApiData(data);
            setLoading(false);
        } else {
            try {
                const data = await ApiRequest.fetch({
                    method: "post",
                    url: `${API_END_POINTS.LAMBDA_QUEUE_START}`,
                    data: {
                        timestamp: new Date(),
                        from: selectFromDate,
                        to: selectToDate,
                        routeShortName: route,
                        agencyId: user.agency.id,
                        reportType: 'sbstReportByNetwork'
                    },
                });

                let { jobId, key, mode } = data;

                if (mode === 'CACHE') {
                    try {
                        const data = await ApiRequest.fetch({
                            method: "get",
                            url: `${API_END_POINTS.LAMBDA_REPORT}/${key}`,
                        });

                        setMainApiData(data.returnData);
                        setLoading(false);
                    } catch (fetchError) {
                        console.error("Error fetching cached data:", fetchError);
                        setLoading(false);
                        setErrorModal(true);
                    }
                } else {
                    const jobStatusInterval = setInterval(async () => {
                        // console.log('checking status');
                        try {
                            const statusResponse = await ApiRequest.fetch({
                                method: "get",
                                url: `${API_END_POINTS.LAMBDA_QUEUE_FIND}`,
                                params: { jobId },
                            });

                            if (statusResponse.status === 'COMPLETED') {
                                // implement download file from S3
                                const data = await ApiRequest.fetch({
                                    method: "get",
                                    url: `${API_END_POINTS.LAMBDA_REPORT}/${key}`,
                                });

                                // console.log('data: ', data);

                                clearInterval(jobStatusInterval); // Stop polling
                                setMainApiData(data.returnData); // Use completed data
                                setLoading(false);
                            } else if (statusResponse.status === 'FAILED') {
                                clearInterval(jobStatusInterval); // Stop polling
                                setLoading(false);
                                setErrorModal(true);
                            }
                        } catch (pollingError) {
                            console.error("Error checking job status:", pollingError);
                            setLoading(false);
                            setErrorModal(true);
                        }
                    }, 5000);
                }
            } catch (error) {
                console.error('Error invoking Lambda function:', error);
            }
        }
    }, [transaction]);

    /* **********
    Table header
    *********** */
    const networkReportTableHeader = useMemo(() => [
        {
            Header: "Route No",
            accessor: "routeShortName",
            // disableFilters: true
        },
        {
            Header: "Total KM Planned (KM)",
            accessor: "totalPlannedKm_",
            disableFilters: true
        },
        {
            Header: "Total KM Served (KM)",
            accessor: "totalMileage_",
            disableFilters: true
        },
        {
            Header: "Total Claim (RM)",
            accessor: "totalClaim_",
            disableFilters: true
        },
        {
            Header: "Total Trips Planned",
            accessor: "totalTripCount_",
            disableFilters: true
        },
        {
            Header: "Total Trips Served",
            accessor: "completedTripCount_",
            disableFilters: true
        },
        {
            Header: "No. of Buses Deployed",
            accessor: "noOfBuses_",
            disableFilters: true
        },
        {
            Header: "Total Missed Trips",
            accessor: "offRouteCount_",
            disableFilters: true
        },
        {
            Header: "Total Trips Late / Early Departure",
            accessor: "lateEarlyDeparture_",
            disableFilters: true
        },
        {
            Header: "Total Breakdown",
            accessor: "tripBreakdown_",
            disableFilters: true
        },
        {
            Header: "Total Accidents",
            accessor: "totalAccident_",
            disableFilters: true
        },
        {
            Header: "Ridership",
            accessor: "totalRidership_",
            disableFilters: true
        },
        {
            Header: "Farebox Collection (RM)",
            accessor: "totalAmount_",
            disableFilters: true
        },
    ], []);

    /* *************
    Generate Report
    ************** */
    const generateExportCsvDataForAllRoute = useCallback(() => {
        if (!mainApiData || mainApiData.length == 0) return toast.warning("Nothing to export!");

        const scheduledAt = moment(mainApiData[0].datetime_, 'YYYY-MM-DD HH:mm:ss');
        const startFrom = scheduledAt.clone().startOf("month").format("DD-MM-YYYY");
        const endTo = scheduledAt.clone().endOf("month").format("DD-MM-YYYY");

        // Header section (single column)
        const headerSection = [
            ["Summary By Network Report"],
            [`Network Operator: ${user.agency.name}`],
            ["Network Area: EMPTY"],
            [`Reporting Period: ${startFrom} - ${endTo}`],
            [`Generated At: ${moment().format("DD/MM/YYYY HH:mm:ss")}`],
            [], // Empty row for separation
        ];

        // Signature section
        const signatureSection = [
            [], // Empty row for spacing
            ["Prepared by,", "", "", "", "Confirmed by,", "", "", "", "Received by,"],
            [], // Space for signature
            [], // Space for signature
            [], // Space for signature
            ["Date:", "", "", "", "", "Date:", "", "", "", "", "Date:"]
        ];

        // Data section
        const tableHeaders = [
            'No.', 'ROUTE NO.', 'TOTAL KM PLANNED (KM)', 'TOTAL KM SERVED (KM)',
            'TOTAL CLAIM (RM)', 'TOTAL TRIPS PLANNED', 'TOTAL TRIPS SERVED', 'NO. OF BUSES DEPLOYED',
            'TOTAL MISSED TRIPS', 'TOTAL TRIPS LATE/EARLY DEPARTURE', 'TOTAL BREAKDOWN', 'TOTAL ACCIDENTS',
            'RIDERSHIP', 'FAREBOX COLLECTION (RM)'
        ];

        const dataSection = [tableHeaders];

        let totals = {
            totalPlannedKm: 0,
            totalMileage: 0,
            totalClaim: 0,
            totalTripCount: 0,
            completedTripCount: 0,
            noOfBuses: 0,
            offRouteCount: 0,
            lateEarlyDeparture: 0,
            tripBreakdown: 0,
            totalAccident: 0,
            totalRidership: 0,
            totalAmount: 0
        };

        mainApiData.forEach((row, index) => {
            dataSection.push([
                index + 1,
                row.routeShortName,
                row.totalPlannedKm_,
                Number(row.totalMileage_),
                row.totalClaim_,
                row.totalTripCount_,
                row.completedTripCount_,
                row.noOfBuses_,
                row.offRouteCount_,
                row.lateEarlyDeparture_,
                row.tripBreakdown_,
                row.totalAccident_,
                row.totalRidership_,
                row.totalAmount_
            ]);

            // Update totals
            totals.totalPlannedKm += Number(row.totalPlannedKm_) || 0;
            totals.totalMileage += Number(row.totalMileage_) || 0;
            totals.totalClaim += Number(row.totalClaim_) || 0;
            totals.totalTripCount += Number(row.totalTripCount_) || 0;
            totals.completedTripCount += Number(row.completedTripCount_) || 0;
            totals.noOfBuses += Number(row.noOfBuses_) || 0;
            totals.offRouteCount += Number(row.offRouteCount_) || 0;
            totals.lateEarlyDeparture += Number(row.lateEarlyDeparture_) || 0;
            totals.tripBreakdown += Number(row.tripBreakdown_) || 0;
            totals.totalAccident += Number(row.totalAccident_) || 0;
            totals.totalRidership += Number(row.totalRidership_) || 0;
            totals.totalAmount += Number(row.totalAmount_) || 0;
        });

        const totalRow = [
            `Total For Network: ${user.agency.name}`,
            '',
            totals.totalPlannedKm.toFixed(2),
            totals.totalMileage.toFixed(2),
            totals.totalClaim.toFixed(2),
            totals.totalTripCount,
            totals.completedTripCount,
            totals.noOfBuses,
            totals.offRouteCount,
            totals.lateEarlyDeparture,
            totals.tripBreakdown,
            totals.totalAccident,
            totals.totalRidership,
            totals.totalAmount.toFixed(2)
        ];

        dataSection.push(totalRow);

        // Combine header and data sections
        const allRows = [...headerSection, ...dataSection, ...signatureSection];

        // Convert to CSV string
        let csvContent = allRows.map(row => row.join(',')).join('\r\n');

        let BOM = "\uFEFF";
        csvContent = BOM + csvContent;
        exportData(
            csvContent,
            `SBST Network Report-${moment(startFrom, "DD-MM-YYYY").format("MMM YYYY")}-${user.agency.name}.csv`,
            "text/csv;charset=utf-8;"
        );
    }, [mainApiData]);

    const AlertSBSTNotAllowed = () => {
        return (
            <div>
                <Alert variant='info' >
                    <Alert.Heading>Report Not Enabled</Alert.Heading>
                    <p>
                        This report is not activated / allowed on your agency. <br />
                        Please contact {" "}
                        <a href="mailto: info@justnaik.com">info@justnaik.com</a> to use this feature.
                    </p>
                </Alert>
            </div>
        );
    };

    const MyBasBody = () => {
        return (
            <div>
                {
                    routesArr ? (
                        <TransactionQuery
                            onClearQuery={handleClearQuery}
                            onSubmitQuery={handleFilterTransactionData}
                            routeOpt={routesArr.map(({ shortName }) => shortName)}
                            driverOpt={driverList}
                            vehicleOpt={vehicleList}
                            agencyNameOpt={[user?.agency?.name]}
                            user={user}
                        />
                    ) : (
                        <div className="d-flex justify-content-center align-items-center">
                            <CircularProgress />
                        </div>
                    )
                }

                <Alert className="mt-3" variant="info">
                    <InfoCircle /> SBST Report only shows data for the past 1
                    months - Contact{" "}
                    <a href="mailto: info@justnaik.com">info@justnaik.com</a> for
                    more
                </Alert>
                <div className='w-100 text-right' >
                    <Button onClick={generateExportCsvDataForAllRoute} >Export Data</Button>
                </div>
                {
                    mainApiData
                        ?
                        <Table numbering columns={networkReportTableHeader} data={mainApiData} />
                        :
                        <Table numbering columns={networkReportTableHeader} data={[]} />
                }

            </div>
        );
    };

    return (
        <ContentWrapper>
            <h2 className='mb-3' >Apad SBST Report by Network</h2>
            <Card className='mt-3' >
                <Card.Body>
                    {
                        user.agency.isAllowSbst ? <MyBasBody /> : <AlertSBSTNotAllowed />
                    }
                </Card.Body>
            </Card>

            <Modal size="sm" centered show={loading}>
                <div className="text-center">
                    <CircularProgress size={70} />
                </div>
            </Modal>

            <ErrorModal showError={errorModal} handleClose={() => setErrorModal(false)} />
        </ContentWrapper>
    );
}

export default MyBasReportByNetworkPage;