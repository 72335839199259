import { connect } from "react-redux";
import MyBasReportPage from "./MyBasReportPage";

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

export default connect(
    mapStateToProps,
    {}
) (MyBasReportPage)