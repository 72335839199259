import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Alert, Card, Col, Form, Row, Button } from 'react-bootstrap'
import _ from 'lodash'
import { InfoCircle } from 'react-bootstrap-icons'
import moment from 'moment'

import { ContentWrapper, JustnaikAppIntro, Modal, SimpleInputGroup, Table } from '../../components'
import { ApiRequest, exportData } from '../../helpers'
import { API_END_POINTS } from '../../config'

import { CircularProgress } from '@material-ui/core'
import { toast } from 'react-toastify'

const WEEKEND_DAY_NUM = [0, 6]

const TransactionQuery = ({ routeOpt = [], vehicleOpt = [], driverOpt = [], onSubmitQuery = () => { }, onClearQuery = () => { } }) => {
    const [route, setRoute] = useState(null)
    const [amPm, setAmPm] = useState('All')
    const [weekendWeekday, setWeekendWeekday] = useState('All')
    const [selectFromDate, setFromDate] = useState(null)
    const [selectToDate, setToDate] = useState(null)
    const [vehicle, setVehicle] = useState(null)
    const [driver, setDriver] = useState(null)
    const [paidBy, setPaidBy] = useState('All')

    const handleSubmitQuery = (e) => {
        e.preventDefault()
        const query = { route, amPm, selectFromDate, selectToDate, vehicle, driver, weekendWeekday, paidBy }

        if (!route || route === 'null') { query['route'] = null }
        if (!selectFromDate || selectFromDate === 'null') { query['selectFromDate'] = null }
        if (!selectToDate || selectToDate === 'null') { query['selectToDate'] = null }
        if (!vehicle || vehicle === 'null') { query['vehicle'] = null }
        if (!driver || driver === 'null') { query['driver'] = null }
        onSubmitQuery(query)
        // 
    }


    const handleClear = () => {
        document.getElementById('date-from').value = ''
        document.getElementById('date-to').value = ''
        setFromDate(null)
        setToDate(null)
        setRoute(null)
        setAmPm('All')
        setWeekendWeekday('All')
        setVehicle(null)
        setDriver(null)
        setPaidBy('All')
        onClearQuery()
    }

    return (
        <Card>
            <Card.Body >
                <h5>Search</h5>
                <Row>
                    <Col lg={4} md={12} sm={12} ></Col>
                    <Col lg={8} md={12} sm={12} >
                        <Form onSubmit={handleSubmitQuery} className='text-right' >
                            <Row>
                                <Col>
                                    <SimpleInputGroup labelWidth={10} preappendText='From Date' >
                                        <Form.Control id='date-from' max={moment().format('YYYY-MM-DD')} onChange={(e) => setFromDate(e.currentTarget.value ? `${e.currentTarget.value} 00:00:00` : e.currentTarget.value)} type='date' />
                                    </SimpleInputGroup>
                                </Col>
                                <Col>
                                    <SimpleInputGroup labelWidth={10} preappendText='To Date' >
                                        <Form.Control id='date-to' min={moment(selectFromDate).add(0, 'day').format('YYYY-MM-DD')} max={moment().format('YYYY-MM-DD')} onChange={(e) => setToDate(e.currentTarget.value ? `${e.currentTarget.value} 23:59:59` : e.currentTarget.value)} type='date' />
                                    </SimpleInputGroup>
                                </Col>
                            </Row>
                            <SimpleInputGroup preappendText="AM/PM" >
                                <Form.Control value={amPm} onChange={(e) => setAmPm(e.currentTarget.value)} custom as='select' >
                                    <option>All</option>
                                    <option>AM</option>
                                    <option>PM</option>
                                </Form.Control>
                            </SimpleInputGroup>
                            <SimpleInputGroup preappendText="Weekend / Weekday" >
                                <Form.Control value={weekendWeekday} onChange={(e) => setWeekendWeekday(e.currentTarget.value)} custom as='select' >
                                    <option>All</option>
                                    <option>Weekend</option>
                                    <option>Weekday</option>
                                </Form.Control>
                            </SimpleInputGroup>
                            <SimpleInputGroup preappendText='Route' >
                                <Form.Control value={route || 'null'} onChange={(e) => setRoute(e.currentTarget.value)} custom as='select' >
                                    <option value={'null'} >select...</option>
                                    {routeOpt.map((opt, i) => <option key={i} value={opt} >{opt}</option>)}
                                </Form.Control>
                            </SimpleInputGroup>
                            <SimpleInputGroup preappendText='Vehicle' >
                                <Form.Control value={vehicle || 'null'} onChange={(e) => setVehicle(e.currentTarget.value)} custom as='select' >
                                    <option value={'null'} >select...</option>
                                    {vehicleOpt.map((opt, i) => <option key={i} value={opt} >{opt}</option>)}
                                </Form.Control>
                            </SimpleInputGroup>
                            <SimpleInputGroup preappendText='Driver' >
                                <Form.Control value={driver || 'null'} onChange={(e) => setDriver(e.currentTarget.value)} custom as='select' >
                                    <option value={'null'} >select...</option>
                                    {driverOpt.map((opt, i) => <option key={i} value={opt} >{opt}</option>)}
                                </Form.Control>
                            </SimpleInputGroup>
                            <SimpleInputGroup preappendText='Paid By' >
                                <Form.Control value={paidBy} onChange={(e) => setPaidBy(e.currentTarget.value)} custom as='select' >
                                    <option>All</option>
                                    <option>Cash</option>
                                    <option>Cashless</option>
                                </Form.Control>
                            </SimpleInputGroup>
                            <Button className='ml-2' onClick={handleClear} variant='warning' >Clear</Button>
                            <Button className='ml-2' type='submit' >Search</Button>
                        </Form>
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    )
}

const CollectionByRoutePage = ({ user }) => {
    const [routeCollection, setRouteCollection] = useState(null)
    const [transaction, setTransaction] = useState(null)
    const [loading, setLoading] = useState(false)
    const [routesArr, setRoutesArr] = useState(null)
    const [filteredRouteCollection, setFilteredRouteCollection] = useState(null)
    const [toDate, setToDate] = useState(moment(new Date()).format('DD-MM-YYYY'));
    const [fromDate, setFromDate] = useState(moment(new Date()).subtract(1, 'months').format('DD-MM-YYYY'));
    // const [routeWithStop, setRouteWithStop] = useState({})

    const handleGetTransactionHistory = async () => {
        setLoading(true)
        try {
            const data = await ApiRequest.fetch({
                method: 'get',
                url: `${API_END_POINTS.TRIP_WITH_STOPS_COLLECTION}`,
                params: {
                    timestamp: new Date()
                }
            })
            setTransaction(data)
            // const uniqueRouteIds = [...new Set(data.map(item => item.routeId))]
            // // const routeWithStops = {}
            // uniqueRouteIds && uniqueRouteIds?.forEach((id) => {
            //     if (!routeWithStops[id]) {
            //         ApiRequest.fetch({
            //             method: 'get',
            //             url: `${API_END_POINTS.STOP_GET_ALL}`,
            //             params: {
            //                 routeId: id
            //             }
            //         }).then((data) => {
            //             // 
            //             // return data
            //             routeWithStops[id] = data
            //         })
            //     }
            // })
            // setRouteWithStop(routeWithStops)
            const sortedData = _.orderBy(data, [({ startedAt }) => new Date(startedAt)], ['desc'])
            const addedLocalTime = sortedData?.map((d) => {
                d['localDate'] = d?.startedAt ? moment(d.startedAt).format('DD-MM-YYYY (ddd)') : 'undefined'
                return d
            })
            const groupedData = _.groupBy(addedLocalTime, 'localDate')
            //
            Object.entries(groupedData).forEach(([localDate, tripsArrayByLocalDate]) => {

                groupedData[localDate] = _.groupBy(tripsArrayByLocalDate, (item) => {
                    return [item['routeShortName'], item['obIb']];
                })
            })
            // Object.entries(groupedData).forEach(([routeShortName, tripsArrayByRouteShortName])=>{
            //     groupedData[routeShortName] = _.groupBy(tripsArrayByRouteShortName, 'obIb')
            // })

            // 
            setRouteCollection(groupedData)
        } catch (error) { }
        finally {
            setLoading(false)
        }
    }

    const handleGetAllRoute = () => {
        ApiRequest.fetch({
            method: 'get',
            url: `${API_END_POINTS.ROUTE_GET_ALL}`,
            params: { showNotActive: false }
        }).then((data) => {
            setRoutesArr(data)
        }).catch(e => { })
    }

    const [driverList, vehicleList] = useMemo(() => {
        if (!transaction) return []
        const drivers = []
        const vehicles = []
        const copy = transaction.reverse() // not because i want this in reverse, but i just want to make a copy without temper the original value
        copy.forEach(({ driverName, vehicleRegistrationNumber }) => {
            drivers.push(driverName)
            vehicles.push(vehicleRegistrationNumber)
        })

        return [_.uniq(drivers), _.uniq(vehicles)]
    }, [transaction])

    useEffect(() => {
        handleGetTransactionHistory()
        handleGetAllRoute()
    }, [])

    const tableHeader = useMemo(() => [
        // {
        //     Header: 'Date',
        //     accessor: 'localTimeGroup_',
        //     // disableFilters: true
        // },
        {
            Header: 'Route',
            accessor: 'routeID_',
            // disableFilters: true
        },
        {
            Header: 'Route Name',
            accessor: 'routeName',
            // disableFilters: true
        },
        {
            Header: 'IB/OB/LOOP',
            accessor: 'obIb_',
            // disableFilters: true
        },
        {
            Header: 'Total Trips',
            accessor: 'totalTripCount_',
            // disableFilters: true
        },
        // {
        //     Header: 'Total Driver',
        //     accessor: 'totalUniqueDriverCount_',
        //     // disableFilters: true
        // },
        // {
        //     Header: 'Total Vehicle',
        //     accessor: 'totalUniqueVehicleCount_',
        //     // disableFilters: true
        // },
        {
            Header: 'Total Collection (MYR)',
            accessor: 'totalAmount_',
            // disableFilters: true
        },
        {
            Header: 'Cash Collection (MYR)',
            accessor: 'cashTotalAmount_',
            // disableFilters: true
        },
        {
            Header: 'Cashless Collection (MYR)',
            accessor: 'cashlessTotalAmount_',
            // disableFilters: true
        },
        // {
        //     Header: 'Total Transaction Count',
        //     accessor: 'totalTransaction_',
        //     // disableFilters: true
        // },
        {
            Header: 'Total Passenger',
            accessor: 'totalRidership_',
            // disableFilters: true
        },
        {
            Header: 'Cash Passenger',
            accessor: 'cashTotalRidership_',
            // disableFilters: true
        },
        {
            Header: 'Cashless Passenger',
            accessor: 'cashlessTotalRidership_',
            // disableFilters: true
        },
        {
            Header: 'Total Adult',
            accessor: 'totalRidership_A',
            // disableFilters: true
        },
        {
            Header: 'Cash Adult',
            accessor: 'cashTotalRidership_A',
            // disableFilters: true
        },
        {
            Header: 'Cashless Adult',
            accessor: 'cashlessTotalRidership_A',
            // disableFilters: true
        },
        {
            Header: 'Total Child',
            accessor: 'totalRidership_C',
            // disableFilters: true
        },
        {
            Header: 'Cash Child',
            accessor: 'cashTotalRidership_C',
            // disableFilters: true
        },
        {
            Header: 'Cashless Child',
            accessor: 'cashlessTotalRidership_C',
            // disableFilters: true
        },
        {
            Header: 'Total Senior',
            accessor: 'totalRidership_S',
            // disableFilters: true
        },
        {
            Header: 'Cash Senior',
            accessor: 'cashTotalRidership_S',
            // disableFilters: true
        },
        {
            Header: 'Cashless Senior',
            accessor: 'cashlessTotalRidership_S',
            // disableFilters: true
        },
        {
            Header: 'Total Disabled',
            accessor: 'totalRidership_D',
            // disableFilters: true
        },
        {
            Header: 'Cash Disabled',
            accessor: 'cashTotalRidership_D',
            // disableFilters: true
        },
        {
            Header: 'Cashless Disabled',
            accessor: 'cashlessTotalRidership_D',
            // disableFilters: true
        },
        {
            Header: 'Total Foreigner Adult',
            accessor: 'totalRidership_FA',
            // disableFilters: true
        },
        {
            Header: 'Cash Foreigner Adult',
            accessor: 'cashTotalRidership_FA',
            // disableFilters: true
        },
        {
            Header: 'Cashless Foreigner Adult',
            accessor: 'cashlessTotalRidership_FA',
            // disableFilters: true
        },
        {
            Header: 'Total Foreigner Child',
            accessor: 'totalRidership_FC',
            // disableFilters: true
        },
        {
            Header: 'Cash Foreigner Child',
            accessor: 'cashTotalRidership_FC',
            // disableFilters: true
        },
        {
            Header: 'Cashless Foreigner Child',
            accessor: 'cashlessTotalRidership_FC',
            // disableFilters: true
        },

    ], [])

    const tabulated = useMemo(() => {
        const returnData = []
        const mainData = filteredRouteCollection
        if (!mainData) return []
        Object.entries(mainData).forEach(([routeShortName1, trxs2]) => {
            const accumulativeTrip = {
                'datetime_': moment(trxs2[0].startedAt).format('DD-MM-YYYY HH:mm:ss (ddd)'),
                'routeID_': new Set(),
                'routeName': new Set(),
                'uniqueDriver_': new Set(),
                'totalUniqueDriverCount_': 0,
                'uniqueTrip_': new Set(),
                'totalTripCount_': 0,
                'uniqueVehicle_': new Set(),
                'totalUniqueVehicleCount_': 0,
                'uniqueJourney_': new Set(),
                'totalTransaction_': 0,
                'totalAmount_': 0,
                'noOfAdult': 0,
                'noOfChild': 0,
                'noOfSenior': 0,
                'noOfOku': 0,
                'noOfForeignAdult': 0,
                'noOfForeignChild': 0,
                'totalRidership_': 0,
                'cashTotalAmount_': 0,
                'cashTotalRidership_': 0,
                'cashlessTotalAmount_': 0,
                'cashlessTotalRidership_': 0,
                'obIb_': new Set(),


                'totalRidership_A': 0,
                'cashTotalRidership_A': 0,
                'cashlessTotalRidership_A': 0,
                'totalRidership_C': 0,
                'cashTotalRidership_C': 0,
                'cashlessTotalRidership_C': 0,
                'totalRidership_S': 0,
                'cashTotalRidership_S': 0,
                'cashlessTotalRidership_S': 0,
                'totalRidership_D': 0,
                'cashTotalRidership_D': 0,
                'cashlessTotalRidership_D': 0,
                'totalRidership_FA': 0,
                'cashTotalRidership_FA': 0,
                'cashlessTotalRidership_FA': 0,
                'totalRidership_FC': 0,
                'cashTotalRidership_FC': 0,
                'cashlessTotalRidership_FC': 0,
            }
            trxs2.map((row) => {
                const totalPax = row.noOfAdult + +row.noOfChild + +row.noOfSenior + +row.noOfOku + +row.noOfForeignAdult + +row.noOfForeignChild
                accumulativeTrip['routeID_'].add(row.routeShortName)
                // const result = routeWithStop[row.routeId]?.filter(obj => {
                //     return obj.directionId == row.obIb
                // })
                // let editedRouteName = `${row.routeShortName} ${result[0]?.name} - ${result[result?.length - 1]?.name}`
                let editedRouteName

                if (row.obIb === 0) {
                    editedRouteName = `${row.routeShortName} ${row.routeName}`
                    accumulativeTrip['routeName'].add(editedRouteName)
                }
                if (row.obIb === 2) {
                    if (row.nameInbound) {
                        editedRouteName = `${row.routeShortName} ${row.nameInbound}`
                        accumulativeTrip['routeName'].add(editedRouteName)
                    } else {
                        editedRouteName = `${row.routeShortName} ${row.routeName}`
                        accumulativeTrip['routeName'].add(editedRouteName)
                    }

                }
                if (row.obIb === 1) {
                    if (row.nameOutbound) {
                        editedRouteName = `${row.routeShortName} ${row.nameOutbound}`
                        accumulativeTrip['routeName'].add(editedRouteName)
                    } else {
                        editedRouteName = `${row.routeShortName} ${row.routeName}`
                        accumulativeTrip['routeName'].add(editedRouteName)
                    }
                }

                accumulativeTrip['uniqueDriver_'].add(row.driverName)
                accumulativeTrip['uniqueVehicle_'].add(row.vehicleRegistrationNumber)
                row.tripId && accumulativeTrip['uniqueTrip_'].add(row.tripId)
                row.journeyId && accumulativeTrip['uniqueJourney_'].add(row.journeyId)
                row.obIb === 0 && accumulativeTrip['obIb_'].add('LOOP')
                row.obIb === 1 && accumulativeTrip['obIb_'].add('OB')
                row.obIb === 2 && accumulativeTrip['obIb_'].add('IB')
                accumulativeTrip['totalAmount_'] += +row.amount
                accumulativeTrip['noOfAdult'] += +row.noOfAdult
                accumulativeTrip['noOfChild'] += +row.noOfChild
                accumulativeTrip['noOfSenior'] += +row.noOfSenior
                accumulativeTrip['noOfOku'] += +row.noOfOku
                accumulativeTrip['noOfForeignAdult'] += +row.noOfForeignAdult
                accumulativeTrip['noOfForeignChild'] += +row.noOfForeignChild
                accumulativeTrip['totalRidership_'] += totalPax
                accumulativeTrip['totalRidership_A'] += +row.noOfAdult
                accumulativeTrip['totalRidership_C'] += +row.noOfChild
                accumulativeTrip['totalRidership_S'] += +row.noOfSenior
                accumulativeTrip['totalRidership_D'] += +row.noOfOku
                accumulativeTrip['totalRidership_FA'] += +row.noOfForeignAdult
                accumulativeTrip['totalRidership_FC'] += +row.noOfForeignChild
                // accumulativeTrip['obIb_'] += 'NA'

                accumulativeTrip['cashTotalAmount_'] += !row.userId && row.paymentType == 'OFFLINE' ? +row.amount : 0
                accumulativeTrip['cashTotalRidership_'] += !row.userId && row.paymentType == 'OFFLINE' ? totalPax : 0
                accumulativeTrip['cashTotalRidership_A'] += !row.userId && row.paymentType == 'OFFLINE' ? +row.noOfAdult : 0
                accumulativeTrip['cashTotalRidership_C'] += !row.userId && row.paymentType == 'OFFLINE' ? +row.noOfChild : 0
                accumulativeTrip['cashTotalRidership_S'] += !row.userId && row.paymentType == 'OFFLINE' ? +row.noOfSenior : 0
                accumulativeTrip['cashTotalRidership_D'] += !row.userId && row.paymentType == 'OFFLINE' ? +row.noOfOku : 0
                accumulativeTrip['cashTotalRidership_FA'] += !row.userId && row.paymentType == 'OFFLINE' ? +row.noOfForeignAdult : 0
                accumulativeTrip['cashTotalRidership_FC'] += !row.userId && row.paymentType == 'OFFLINE' ? +row.noOfForeignChild : 0

                accumulativeTrip['cashlessTotalAmount_'] += row.userId || row.paymentType != 'OFFLINE' ? +row.amount : 0
                accumulativeTrip['cashlessTotalRidership_'] += row.userId || row.paymentType != 'OFFLINE' ? totalPax : 0
                accumulativeTrip['cashlessTotalRidership_A'] += row.userId || row.paymentType != 'OFFLINE' ? +row.noOfAdult : 0
                accumulativeTrip['cashlessTotalRidership_C'] += row.userId || row.paymentType != 'OFFLINE' ? +row.noOfChild : 0
                accumulativeTrip['cashlessTotalRidership_S'] += row.userId || row.paymentType != 'OFFLINE' ? +row.noOfSenior : 0
                accumulativeTrip['cashlessTotalRidership_D'] += row.userId || row.paymentType != 'OFFLINE' ? +row.noOfOku : 0
                accumulativeTrip['cashlessTotalRidership_FA'] += row.userId || row.paymentType != 'OFFLINE' ? +row.noOfForeignAdult : 0
                accumulativeTrip['cashlessTotalRidership_FC'] += row.userId || row.paymentType != 'OFFLINE' ? +row.noOfForeignChild : 0
            })

            accumulativeTrip['totalUniqueDriverCount_'] = accumulativeTrip.uniqueDriver_.size
            accumulativeTrip['totalUniqueVehicleCount_'] = accumulativeTrip.uniqueVehicle_.size
            accumulativeTrip['totalTripCount_'] = accumulativeTrip.uniqueTrip_.size
            accumulativeTrip['totalTransaction_'] = accumulativeTrip.uniqueJourney_.size
            // accumulativeTrip['localTimeGroup_'] = localTimeGroup
            accumulativeTrip['trxs'] = trxs2
            accumulativeTrip['routeShortName_'] = routeShortName1

            //format amount
            accumulativeTrip['totalAmount_'] = (accumulativeTrip['totalAmount_']).toFixed(2)
            accumulativeTrip['cashTotalAmount_'] = (accumulativeTrip['cashTotalAmount_']).toFixed(2)
            accumulativeTrip['cashlessTotalAmount_'] = (accumulativeTrip['cashlessTotalAmount_']).toFixed(2)

            returnData.push(accumulativeTrip)
        })
        // })
        return returnData
    }, [filteredRouteCollection])
    // 

    const handleFilterTransactionData = useCallback(({ route, amPm, selectFromDate, selectToDate, vehicle, driver, weekendWeekday, paidBy }) => {
        if (!transaction) return []
        const filtered = transaction?.filter(({ createdAt, startedAt, routeShortName, driverName, vehicleRegistrationNumber, userId }) => {
            let returnVal = true
            if (amPm !== 'All') {
                returnVal = String(moment(startedAt).format('a')).toLowerCase() === String(amPm).toLowerCase()
                if (!returnVal) return false
            }

            if (weekendWeekday !== 'All') {
                const isWeekendWeekday = WEEKEND_DAY_NUM.includes(new Date(startedAt).getDay()) ? 'Weekend' : 'Weekday'
                returnVal = isWeekendWeekday === weekendWeekday
                if (!returnVal) return false
            }

            if (selectFromDate) {
                setFromDate(moment(selectFromDate).format('DD-MM-YYYY'))
                returnVal = new Date(startedAt).valueOf() >= new Date(selectFromDate).valueOf()
                if (!returnVal) return false
            }

            if (selectToDate) {
                setToDate(moment(selectToDate).format('DD-MM-YYYY'))
                returnVal = new Date(startedAt).valueOf() <= new Date(selectToDate).valueOf()
                if (!returnVal) return false
            }

            if (route) {
                returnVal = routeShortName === route
                if (!returnVal) return false
            }

            if (vehicle) {
                returnVal = vehicleRegistrationNumber === vehicle
                if (!returnVal) return false
            }

            if (driver) {
                returnVal = driverName === driver
                if (!returnVal) return false
            }

            if (paidBy !== 'All') {
                returnVal = userId ? 'cashless' : 'cash' === String(paidBy).toLowerCase()
                if (!returnVal) return false
            }

            return true
        })

        const sortedData = _.orderBy(filtered, [({ startedAt }) => new Date(startedAt)], ['desc'])
        let sortedDataOrdered = _.orderBy(sortedData, ['routeShortName', 'obIb'],
            ['asc', 'asc']);
        const addedLocalTime = sortedDataOrdered?.map((d) => {
            d['localDate'] = d?.startedAt ? moment(d.startedAt).format('DD-MM-YYYY (ddd)') : 'undefined'
            return d
        })
        const groupedData = _.groupBy(addedLocalTime, (item) => {
            return [item['routeShortName'], item['obIb']];
        })
        setFilteredRouteCollection(groupedData)
    }, [transaction])

    const handleClearQuery = useCallback(() => {
        setRouteCollection(null)
    }, [])

    const generateExportCsvData = useCallback(() => {
        if (tabulated?.length === 0) return toast.warning('Nothing to export!')
        const reportNameHeader = 'Collection by Routes Report' + `\r\n\n`
        const networkOperator = 'Network Area:,EMPTY' + '\r\n'
        const networkArea = `Network Operator:,${user.agency.name}\r\n`
        const datesGeneratedFor = fromDate && toDate ? `Reporting Period :,${moment(fromDate).format('MM/DD/YYYY')} - ${(toDate).split('-').join('/')}\r\n` : ""
        const dateGenerated = `Generated At:, ${moment().format("DD/MM/YYYY")}\r\n`
        const generatedBy = `Generated By:,${user.firstName} ${user.lastName}\r\n\n`

        const header = "NO.,Route,Route Name,OB/IB/LOOP,Total Trips,Total Collection,Cash Collection,Cashless collection, Total Passenger, Cash Passenger, Cashless Passenger,Total Adult,Cash Adult, Cashless Adult,Total Child,Cash Child, Cashless Child,Total Senior,Cash Senior, Cashless Senior,Total Disabled,Cash Disabled, Cashless Disabled,Total Foreign Adult,Cash Foreign Adult, Cashless Foreign Adult,Total Foreign Child,Cash Foreign Child, Cashless Foreign Child\r\n"
        let data = reportNameHeader + networkArea + networkOperator + datesGeneratedFor + dateGenerated + generatedBy

        data += header

        let totalTripCount_T = 0
        let totalAmount_T = 0
        let totalRidership_T = 0
        let totalRidership_AT = 0
        let totalRidership_CT = 0
        let totalRidership_ST = 0
        let totalRidership_DT = 0
        let totalRidership_FAT = 0
        let totalRidership_FCT = 0
        // accumulativeTrip['obIb_'] += 'NA'

        let cashTotalAmount_T = 0
        let cashTotalRidership_T = 0
        let cashTotalRidership_AT = 0
        let cashTotalRidership_CT = 0
        let cashTotalRidership_ST = 0
        let cashTotalRidership_DT = 0
        let cashTotalRidership_FAT = 0
        let cashTotalRidership_FCT = 0

        let cashlessTotalAmount_T = 0
        let cashlessTotalRidership_T = 0
        let cashlessTotalRidership_AT = 0
        let cashlessTotalRidership_CT = 0
        let cashlessTotalRidership_ST = 0
        let cashlessTotalRidership_DT = 0
        let cashlessTotalRidership_FAT = 0
        let cashlessTotalRidership_FCT = 0
        
        tabulated.forEach(({
            routeID_, routeName, obIb_, totalTripCount_, totalAmount_, cashTotalAmount_, cashlessTotalAmount_, totalRidership_, cashTotalRidership_, cashlessTotalRidership_
            , totalRidership_A, cashTotalRidership_A, cashlessTotalRidership_A, totalRidership_C, cashTotalRidership_C, cashlessTotalRidership_C, totalRidership_S, cashTotalRidership_S, cashlessTotalRidership_S, totalRidership_D, cashTotalRidership_D, cashlessTotalRidership_D, totalRidership_FA, cashTotalRidership_FA, cashlessTotalRidership_FA, totalRidership_FC, cashTotalRidership_FC, cashlessTotalRidership_FC }, indexTop) => {

            totalTripCount_T += +totalTripCount_
            totalAmount_T += +totalAmount_
            totalRidership_T += +totalRidership_
            totalRidership_AT += +totalRidership_A
            totalRidership_CT += +totalRidership_C
            totalRidership_ST += +totalRidership_S
            totalRidership_DT += +totalRidership_D
            totalRidership_FAT += +totalRidership_FA
            totalRidership_FCT += +totalRidership_FC
            // accumulativeTrip['obIb_'] += 'NA'

            cashTotalAmount_T += +cashTotalAmount_
            cashTotalRidership_T += +cashTotalRidership_
            cashTotalRidership_AT += +cashTotalRidership_A
            cashTotalRidership_CT += +cashTotalRidership_C
            cashTotalRidership_ST += +cashTotalRidership_S
            cashTotalRidership_DT += +cashTotalRidership_D
            cashTotalRidership_FAT += +cashTotalRidership_FA
            cashTotalRidership_FCT += +cashTotalRidership_FC

            cashlessTotalAmount_T += +cashlessTotalAmount_
            cashlessTotalRidership_T += +cashlessTotalRidership_
            cashlessTotalRidership_AT += +cashlessTotalRidership_A
            cashlessTotalRidership_CT += +cashlessTotalRidership_C
            cashlessTotalRidership_ST += +cashlessTotalRidership_S
            cashlessTotalRidership_DT += +cashlessTotalRidership_D
            cashlessTotalRidership_FAT += +cashlessTotalRidership_FA
            cashlessTotalRidership_FCT += +cashlessTotalRidership_FC

            data += `${indexTop + 1},${[...routeID_][0]},${[...routeName][0]},${[...obIb_][0]},${totalTripCount_},${totalAmount_},${cashTotalAmount_},${cashlessTotalAmount_},${totalRidership_},${cashTotalRidership_},${cashlessTotalRidership_},${totalRidership_A},${cashTotalRidership_A},${cashlessTotalRidership_A},${totalRidership_C},${cashTotalRidership_C},${cashlessTotalRidership_C},${totalRidership_S},${cashTotalRidership_S},${cashlessTotalRidership_S},${totalRidership_D},${cashTotalRidership_D},${cashlessTotalRidership_D},${totalRidership_FA},${cashTotalRidership_FA},${cashlessTotalRidership_FA},${totalRidership_FC},${cashTotalRidership_FC},${cashlessTotalRidership_FC}
                `})
        data += `TOTAL,,,,${totalTripCount_T},${totalAmount_T.toFixed(2)},${cashTotalAmount_T.toFixed(2)},${cashlessTotalAmount_T},${totalRidership_T},${cashTotalRidership_T},${cashlessTotalRidership_T},${totalRidership_AT},${cashTotalRidership_AT},${cashlessTotalRidership_AT},${totalRidership_CT},${cashTotalRidership_CT},${cashlessTotalRidership_CT},${totalRidership_ST},${cashTotalRidership_ST},${cashlessTotalRidership_ST},${totalRidership_DT},${cashTotalRidership_DT},${cashlessTotalRidership_DT},${totalRidership_FAT},${cashTotalRidership_FAT},${cashlessTotalRidership_FAT},${totalRidership_FCT},${cashTotalRidership_FCT},${cashlessTotalRidership_FCT}`

        exportData(data, `Collection by Route Report-${moment().format('YYYYMMDD_HHmmss')}.csv`, 'text/csv;charset=utf-8;')

    }, [tabulated])

    return (
        <ContentWrapper >
            <h2 className='mb-3' >Collection By Route</h2>
            <Card className='mt-3' >
                <Card.Body>
                    {
                        transaction && transaction.length > 0 ?
                            <div>
                                {
                                    routesArr && driverList && vehicleList ?
                                        <TransactionQuery onClearQuery={handleClearQuery} onSubmitQuery={handleFilterTransactionData} routeOpt={routesArr.map(({ shortName }) => shortName)} driverOpt={driverList} vehicleOpt={vehicleList} agencyNameOpt={[user?.agency?.name]} />
                                        :
                                        <div className='d-flex justify-content-center align-items-center' >
                                            <CircularProgress />
                                        </div>
                                }
                                <Alert className='mt-3' variant='info' > <InfoCircle />Collection by Route only shows data for the past 3 months - Contact <a href="mailto: info@justnaik.com" >info@justnaik.com</a> for more</Alert>
                                <div className='w-100 text-right' >
                                    <Button onClick={generateExportCsvData} >Export Data</Button>
                                </div>
                                <Table numbering columns={tableHeader} data={tabulated} />
                            </div>
                            :
                            <JustnaikAppIntro />
                    }
                </Card.Body>
            </Card>
            <Modal size='sm' centered show={loading} >
                <div className='text-center' >
                    <CircularProgress size={70} />
                </div>
            </Modal>
        </ContentWrapper>
    )
}

export default CollectionByRoutePage







