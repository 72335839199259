import React, { useEffect, useMemo, useState } from 'react'
import { Alert, Card, Col, Form, Row, Button } from 'react-bootstrap'
import _ from 'lodash'
import { InfoCircle } from 'react-bootstrap-icons'
import moment from 'moment'

import { ContentWrapper, JustnaikAppIntro, Modal, SimpleInputGroup, Table } from '../../components'
import { ApiRequest, exportData } from '../../helpers'
import { API_END_POINTS } from '../../config'

import './TransactionPage.css'
import { CircularProgress } from '@material-ui/core'
import { toast } from 'react-toastify'

const WEEKEND_DAY_NUM = [0, 6]

const TransactionQuery = ({ routeOpt = [], vehicleOpt = [], driverOpt = [], onSubmitQuery = () => { }, onClearQuery = () => { } }) => {
    const [route, setRoute] = useState(null)
    const [amPm, setAmPm] = useState('All')
    const [weekendWeekday, setWeekendWeekday] = useState('All')
    const [selectFromDate, setFromDate] = useState(null)
    const [selectToDate, setToDate] = useState(null)
    const [vehicle, setVehicle] = useState(null)
    const [driver, setDriver] = useState(null)

    const handleSubmitQuery = (e) => {
        e.preventDefault()
        const query = { route, amPm, selectFromDate, selectToDate, vehicle, driver, weekendWeekday }

        if (!route || route === 'null') { query['route'] = null }
        if (!selectFromDate || selectFromDate === 'null') { query['selectFromDate'] = null }
        if (!selectToDate || selectToDate === 'null') { query['selectToDate'] = null }
        if (!vehicle || vehicle === 'null') { query['vehicle'] = null }
        if (!driver || driver === 'null') { query['driver'] = null }
        onSubmitQuery(query)
        // 
    }

    const handleClear = () => {
        document.getElementById('date-from').value = ''
        document.getElementById('date-to').value = ''
        setFromDate(null)
        setToDate(null)
        setRoute(null)
        setAmPm('All')
        setWeekendWeekday('All')
        setVehicle(null)
        setDriver(null)
        onClearQuery()
    }

    return (
        <Card>
            <Card.Body >
                <h5>Search</h5>
                <Row>
                    <Col lg={4} md={12} sm={12} ></Col>
                    <Col lg={8} md={12} sm={12} >
                        <Form onSubmit={handleSubmitQuery} className='text-right' >
                            <Row>
                                <Col>
                                    <SimpleInputGroup labelWidth={10} preappendText='From Date' >
                                        <Form.Control id='date-from' max={moment().format('YYYY-MM-DD')} onChange={(e) => setFromDate(e.currentTarget.value ? `${e.currentTarget.value} 00:00:00` : e.currentTarget.value)} type='date' />
                                    </SimpleInputGroup>
                                </Col>
                                <Col>
                                    <SimpleInputGroup labelWidth={10} preappendText='To Date' >
                                        <Form.Control id='date-to' min={moment(selectFromDate).format('YYYY-MM-DD')} max={moment().format('YYYY-MM-DD')} onChange={(e) => setToDate(e.currentTarget.value ? `${e.currentTarget.value} 23:59:59` : e.currentTarget.value)} type='date' />
                                    </SimpleInputGroup>
                                </Col>
                            </Row>
                            <SimpleInputGroup preappendText="AM/PM" >
                                <Form.Control value={amPm} onChange={(e) => setAmPm(e.currentTarget.value)} custom as='select' >
                                    <option>All</option>
                                    <option>AM</option>
                                    <option>PM</option>
                                </Form.Control>
                            </SimpleInputGroup>
                            <SimpleInputGroup preappendText="Weekend / Weekday" >
                                <Form.Control value={weekendWeekday} onChange={(e) => setWeekendWeekday(e.currentTarget.value)} custom as='select' >
                                    <option>All</option>
                                    <option>Weekend</option>
                                    <option>Weekday</option>
                                </Form.Control>
                            </SimpleInputGroup>
                            <SimpleInputGroup preappendText='Route' >
                                <Form.Control value={route || 'null'} onChange={(e) => setRoute(e.currentTarget.value)} custom as='select' >
                                    <option value={'null'} >select...</option>
                                    {routeOpt.map((opt, i) => <option key={i} value={opt} >{opt}</option>)}
                                </Form.Control>
                            </SimpleInputGroup>
                            <SimpleInputGroup preappendText='Vehicle' >
                                <Form.Control value={vehicle || 'null'} onChange={(e) => setVehicle(e.currentTarget.value)} custom as='select' >
                                    <option value={'null'} >select...</option>
                                    {vehicleOpt.map((opt, i) => <option key={i} value={opt} >{opt}</option>)}
                                </Form.Control>
                            </SimpleInputGroup>
                            <SimpleInputGroup preappendText='Driver' >
                                <Form.Control value={driver || 'null'} onChange={(e) => setDriver(e.currentTarget.value)} custom as='select' >
                                    <option value={'null'} >select...</option>
                                    {driverOpt.map((opt, i) => <option key={i} value={opt} >{opt}</option>)}
                                </Form.Control>
                            </SimpleInputGroup>
                            <Button className='ml-2' onClick={handleClear} variant='warning' >Clear</Button>
                            <Button className='ml-2' type='submit' >Search</Button>
                        </Form>
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    )
}

const TransactionPage = ({ user }) => {
    const [transaction, setTransaction] = useState(null)
    const [loading, setLoading] = useState(false)
    const [loadingCounter, setLoadingCounter] = useState(0);
    const [todayTransaction, setTodayTransaction] = useState(null)
    const [routesArr, setRoutesArr] = useState(null)
    const [filteredTransaction, setFilteredTransaction] = useState(null)

    const handleGetTransactionHistory = async () => {
        setLoading(true)
        try {
            setLoadingCounter(prev => prev + 1)
            const data = await ApiRequest.fetch({
                method: 'get',
                url: `${API_END_POINTS.TRANSACTION_GET_ALL}`,
                params: {
                    timestamp: new Date()
                }
            })
            //
            const sortedData = _.orderBy(data, [({ createdAt }) => new Date(createdAt)], ['asc'])
            
            setTransaction(sortedData)
        } catch (error) { }
        finally {
            setLoading(false)
            setLoadingCounter(prev => prev - 1);
        }
    }

    const handleGetTransactionToday = async () => {
        setLoading(true)
        try {
            setLoadingCounter(prev => prev + 1)
            const data = await ApiRequest.fetch({
                method: 'get',
                url: `${API_END_POINTS.TRANSACTION_GET_TODAY}`,
                params: {
                    timestamp: new Date()
                }
            })
            // 
            const sortedData = _.orderBy(data, [({ createdAt }) => new Date(createdAt)], ['desc'])
            setTodayTransaction(sortedData)
        } catch (error) { }
        finally {
            setLoading(false)
            setLoadingCounter(prev => prev - 1);
        }
    }

    const handleGetAllRoute = () => {
        setLoadingCounter(prev => prev + 1);
        setLoading(true);
        ApiRequest.fetch({
            method: 'get',
            url: `${API_END_POINTS.ROUTE_GET_ALL}`,
            params: { showNotActive: false }
        }).then((data) => {
            setRoutesArr(data)
        }).catch(e => { }).finally(() => {
            setLoading(false);
            setLoadingCounter(prev => prev - 1);
        })
    }

    const [driverList, vehicleList] = useMemo(() => {
        if (!transaction) return []
        const drivers = []
        const vehicles = []
        const copy = transaction.reverse() // not because i want this in reverse, but i just want to make a copy without temper the original value
        copy.forEach(({ driverName, vehicleRegistrationNumber }) => {
            drivers.push(driverName)
            vehicles.push(vehicleRegistrationNumber)
        })

        return [_.uniq(drivers), _.uniq(vehicles)]
    }, [transaction])

    useEffect(() => {
        handleGetTransactionHistory()
        handleGetTransactionToday()
        handleGetAllRoute()
    }, [])

    const tableHeader = useMemo(() => [
        {
            Header: 'Sales Timestamp',
            accessor: 'datetime_',
            // disableFilters: true
        },
        {
            Header: 'Trip ID',
            accessor: 'tripId',
            // disableFilters: true
        },
        {
            Header: 'Serial Trip ID',
            accessor: 'agencyTripId',
            // disableFilters: true
        },
        {
            Header: 'Ticket ID',
            accessor: 'ticketId_',
            // disableFilters: true
        },
        {
            Header: 'Route ID',
            accessor: 'routeShortName',
            // disableFilters: true
        },
        {
            Header: 'OB/IB/LOOP',
            accessor: 'obIb_',
            // disableFilters: true
        },
        {
            Header: 'From',
            accessor: 'startStopName',
            // disableFilters: true
        },
        {
            Header: 'To',
            accessor: 'endStopName',
            // disableFilters: true
        },
        {
            Header: 'Adult',
            accessor: 'noOfAdult',
            // disableFilters: true
        },
        {
            Header: 'Child',
            accessor: 'noOfChild',
            // disableFilters: true
        },
        {
            Header: 'Senior',
            accessor: 'noOfSenior',
            // disableFilters: true
        },
        {
            Header: 'OKU',
            accessor: 'noOfOku',
            // disableFilters: true
        },
        {
            Header: 'Foreign (Adult)',
            accessor: 'noOfForeignAdult',
            // disableFilters: true
        },
        {
            Header: 'Foreign (Child)',
            accessor: 'noOfForeignChild',
            // disableFilters: true
        },
        {
            Header: 'Pax',
            accessor: 'pax_',
            // disableFilters: true
        },
        // {
        //     Header: 'Price',
        //     accessor: 'fareTypeAmount_',
        //     // disableFilters: true
        // },
        {
            Header: 'Pax Amount (MYR)',
            accessor: 'amount',
            // disableFilters: true
        },
        {
            Header: 'Paid By',
            accessor: 'paidBy_',
            // disableFilters: true
        },
        {
            Header: 'Checkout Timestamp',
            accessor: 'checkoutTime_',
            // disableFilters: true
        },
        {
            Header: 'Driver Name',
            accessor: 'driverName',
            // disableFilters: true
        },
        {
            Header: 'Vehicle',
            accessor: 'vehicleRegistrationNumber',
            // disableFilters: true
        },
        {
            Header: 'Device Serial No.',
            accessor: 'deviceSerialNumber',
            // disableFilters: true
        },
        {
            Header: 'Transaction ID',
            accessor: 'trans_id',
            // disableFilters: true
        }
    ], [])

    const appendAction = (data) => {
        if (!data.length > 0) return []
        data.map((row, i) => {
            const totalPax = row.noOfAdult + +row.noOfChild + +row.noOfSenior + +row.noOfOku + +row.noOfForeignAdult + +row.noOfForeignChild
            row['datetime_'] = moment(row.userId ? row.createdAt : row.endedAt).format('DD-MM-YYYY HH:mm:ss (ddd)')
            row['checkoutTime_'] = moment(row.endedAt).format('DD-MM-YYYY HH:mm:ss')
            row['ticketId_'] = row.agencyJourneyId || `TRIP_ID_${row.journeyId}`
            // row['paxAmount_'] = totalPax * row.amount
            row['pax_'] = totalPax
            row['obIb_'] = row.obIb === 0 ? 'LOOP' : row.obIb === 1 ? 'OB' : 'IB'
            row['fareType_'] = `${row.noOfAdult}, ${row.noOfChild}, ${row.noOfSenior}, ${row.noOfOku}, ${row.noOfForeignAdult}, ${row.noOfForeignChild}`
            row['paidBy_'] = row.paymentType == 'OFFLINE' ? 'Cash' : row.paymentType == 'ONLINE' ? 'Cashless' : row.paymentType == 'FP_CARD' ? 'FP Card' :  row.paymentType == 'FP_QR' ? 'FP QR' :'JN CARD'
        })
        return data
    }

    const handleFilterTransactionData = ({ route, amPm, selectFromDate, selectToDate, vehicle, driver, weekendWeekday }) => {
        if (!transaction) return []
        const filtered = transaction.filter(({ createdAt, routeShortName, driverName, vehicleRegistrationNumber }) => {
            let returnVal = true
            if (amPm !== 'All') {
                returnVal = String(moment(createdAt).format('a')).toLowerCase() === String(amPm).toLowerCase()
                if (!returnVal) return false
            }

            if (weekendWeekday !== 'All') {
                const isWeekendWeekday = WEEKEND_DAY_NUM.includes(new Date(createdAt).getDay()) ? 'Weekend' : 'Weekday'
                returnVal = isWeekendWeekday === weekendWeekday
                if (!returnVal) return false
            }

            if (selectFromDate) {
                returnVal = new Date(createdAt).valueOf() >= new Date(selectFromDate).valueOf()
                if (!returnVal) return false
            }

            if (selectToDate) {
                returnVal = new Date(createdAt).valueOf() <= new Date(selectToDate).valueOf()
                if (!returnVal) return false
            }

            if (route) {
                returnVal = routeShortName === route
                if (!returnVal) return false
            }

            if (vehicle) {
                returnVal = vehicleRegistrationNumber === vehicle
                if (!returnVal) return false
            }

            if (driver) {
                returnVal = driverName === driver
                if (!returnVal) return false
            }

            return true
        })
        setFilteredTransaction(filtered)
        // console.log('trans: ', filtered)
    }

    const handleClearQuery = () => {
        setFilteredTransaction(null)
    }

    const generateExportCsvData = () => {
        const f = filteredTransaction || transaction || null
        if (f?.length === 0) return toast.warning('Nothing to export!')
        const dateGenerated = `Generated At:, ${new Date().toString()}\r\n`
        const generatedBy = `Generated By:, ${user.firstName} ${user.lastName}\r\n\n`
        const header = "Sales Date, Sales Time, Trip ID, Serial Trip ID, Ticket ID, Route ID,OB/IB/LOOP, From, To, Adult, Child, Senior, OKU, Foreign (Adult), Foreign (Child), Pax, Amount, Paid By, Checkout Date, Checkout Time, Driver Name, Vehicle No., Device Serial No., Transaction ID\r\n"
        let data = dateGenerated + generatedBy + header
        f.forEach(({ createdAt, agencyTripId, tripId, ticketId_, routeShortName, obIb, startStopName, endStopName, noOfAdult, noOfChild, noOfSenior, noOfOku, noOfForeignAdult, noOfForeignChild, pax_, amount, paidBy_, endedAt, driverName, vehicleRegistrationNumber, deviceSerialNumber, trans_id }) => {
            data += `${moment(endedAt).format('YYYY/MM/DD, HH:mm:ss')}, ${tripId}, ${agencyTripId}, ${ticketId_}, ${routeShortName}, ${obIb === 0 ? 'LOOP' : obIb === 1 ? 'OB' : 'IB'}, ${startStopName}, ${endStopName}, ${noOfAdult}, ${noOfChild}, ${noOfSenior}, ${noOfOku}, ${noOfForeignAdult}, ${noOfForeignChild}, ${pax_}, ${amount}, ${paidBy_}, ${moment(endedAt).format('YYYY/MM/DD, HH:mm:ss')}, ${driverName}, ${vehicleRegistrationNumber}, ${deviceSerialNumber}, ${trans_id}\r\n`
        })

        exportData(data, `Transaction History ${moment().format('YYYYMMDD_HHmmss')}.csv`, 'text/csv;charset=utf-8;')
    }

    return (
        <ContentWrapper >
            <h2 className='mb-3' >Transaction History</h2>
            {
                todayTransaction && todayTransaction.length > 0 &&
                <Card >
                    <Card.Body>
                        <Card.Title>Today's Transaction</Card.Title>
                        <Table numbering columns={tableHeader} data={appendAction(todayTransaction)} />
                    </Card.Body>
                </Card>
            }
            <Card className='mt-3' >
                <Card.Body>
                    <Card.Title>History</Card.Title>
                    {
                        transaction && transaction.length > 0 ?
                            <div>
                                {
                                    routesArr && driverList && vehicleList ?
                                        <TransactionQuery onClearQuery={handleClearQuery} onSubmitQuery={handleFilterTransactionData} routeOpt={routesArr.map(({ shortName }) => shortName)} driverOpt={driverList} vehicleOpt={vehicleList} agencyNameOpt={[user?.agency?.name]} />
                                        :
                                        <div className='d-flex justify-content-center align-items-center' >
                                            <CircularProgress />
                                        </div>
                                }
                                <Alert className='mt-3' variant='info' > <InfoCircle /> Transaction History only shows data for the past 6 months - Contact <a href="mailto: info@justnaik.com" >info@justnaik.com</a> for more</Alert>
                                <div className='w-100 text-right' >
                                    <Button onClick={generateExportCsvData} >Export Data</Button>
                                </div>
                                <Table numbering columns={tableHeader} data={appendAction(filteredTransaction || transaction)} />
                            </div>
                            :
                            <JustnaikAppIntro />
                    }
                </Card.Body>
            </Card>
            <Modal size='sm' centered show={loadingCounter > 0} >
                <div className='text-center' >
                    <CircularProgress size={70} />
                </div>
            </Modal>
        </ContentWrapper>
    )
}

export default TransactionPage