import React, { useState } from 'react'
import { Accordion, Card, Nav } from 'react-bootstrap'
import { Clock, PencilSquare, Shuffle, Geo, PersonBadge,Wallet,Hourglass, Truck, Phone, ClockHistory, Lightning, CashStack, GraphUp, ClipboardCheck, EmojiSmile, Cash, ClipboardData, BarChart, CalendarWeek, JournalText } from 'react-bootstrap-icons'
import { Link } from 'react-router-dom'

import justnaikFull from '../../assets/logo/justnaik_full.png'
import logoInverse from '../../assets/logo/logo_inverse.png'

import "./Sidebar.css"

const sideBarMenu = [
    {
        title: 'Real-Time',
        Icon: Clock,
        path: '/'
    },
    {
        title: 'Quick Start GTFS',
        Icon: Lightning,
        path: '/quick-start-gtfs',
    },
    {
        title: 'Editorial Management',
        Icon: PencilSquare,
        path: '/editorial',
        sub: [
            {
                title: 'Stop',
                Icon: Geo,
                path: '/editorial/stop',
            },
            {
                title: 'Route',
                Icon: Shuffle,
                path: '/editorial/route',
            },
            {
                title: 'Device',
                Icon: Phone,
                path: '/editorial/device',
            },
            {
                title: 'Vehicle',
                Icon: Truck,
                path: '/editorial/vehicle',
            },
            {
                title: 'Driver',
                Icon: PersonBadge,
                path: '/editorial/driver',
            },
        ]
    },
    // {
    //     title: 'Schedule',
    //     Icon: CalendarWeek,
    //     path: '/schedule',
    // },
    {
        title: 'Scheduler',
        Icon: CalendarWeek,
        path: '/schedule-new',
    },
    {
        title: 'Collection',
        Icon: CashStack,
        path: '/summary',
        sub: [
            {
                title: 'Summary',
                Icon: GraphUp,
                path: '/collection-summary',
            },
            {
                title: 'Transaction History',
                Icon: ClockHistory,
                path: '/transaction',
            },
            {
                title: 'Trip Collection',
                Icon: Truck,
                path: '/trip-collection',
            },
            {
                title: 'Driver Collection',
                Icon: PersonBadge,
                path: '/driver-collection',
            },
            {
                title: 'Route Collection',
                Icon: Shuffle,
                path: '/route-collection',
            },
            {
                title: 'Collection By Route',
                Icon: Shuffle,
                path: '/collection-route',
            },
            {
                title: 'Vehicle Collection',
                Icon: Truck,
                path: '/vehicle-collection',
            },
            {
                title: 'Settlement Report',
                Icon: ClipboardCheck,
                path: '/settlement',
            },
            {
                title: 'Fare Collection',
                Icon: Cash,
                path: '/fare-collection',
            },
            {
                title: 'Total KM Report',
                Icon: Truck,
                path: '/total-km',
            }
        ]
    },
    {
        title: 'Custom Report',
        Icon: CashStack,
        path: '/summary',
        sub: [
            {
                title: 'Claim Details',
                Icon: GraphUp,
                path: '/claim',
            },
            {
                title: 'Trip Missed Report',
                Icon: Hourglass,
                path: '/missed-trips',
            },
            {
                title: 'ISBSF Report',
                Icon: ClockHistory,
                path: '/isbsf',
            },
            {
                title: 'Bus Transfer',
                Icon: Truck,
                path: '/bus-transfer',
            },
            {
                title: 'Sales Report',
                Icon: Wallet,
                path: '/sales',
            },
            {
                title: 'Apad Compare',
                Icon: GraphUp,
                path: '/apad-trips',
            },{
                title: 'Apad Scoreboard',
                Icon: JournalText,
                path: '/scoreboard',
            },
            {
                title: 'MyBas Report by Network',
                Icon: ClockHistory,
                path: '/mybas-network',
            },
            {
                title: 'MyBas Report by Route',
                Icon: ClockHistory,
                path: '/mybas-route',
            },
            {
                title: 'MyBas Monthly Summary',
                Icon: ClockHistory,
                path: '/mybas-monthly-summary',
            },
        ]
    },
    {
        title: 'History Log',
        Icon: Clock,
        path: '/history-log',
    },
    {
        title: 'Trip Experience',
        Icon: EmojiSmile,
        path: '/trip-experience',
    },
    // {
    //     title: 'Report',
    //     Icon: ClipboardData,
    //     path: '/report',
    // },
    {
        title: 'Analytics',
        Icon: BarChart,
        path: '/analytics',
    }
]

const Sidebar = ({ children,user }) => {
    const [openDrawer, setOpenDrawer] = useState(false)

    const handleOpenDrawer = () => {
        setOpenDrawer(true)
    }

    const handleCloseDrawer = () => {
        if (openDrawer) return setOpenDrawer(false)
    }

    const currrentLoc = window.location.pathname

    return (
        <div className='sidebar-container' >
            <div onMouseEnter={handleOpenDrawer} >
                <Nav className={`d-flex flex-column bg-dark ${openDrawer ? 'sidebar' : 'sidebar-collapse'}`}
                // onSelect={selectedKey => alert(`selected ${selectedKey}`)}
                >
                    <div className='nav-main' >
                        <Link to='/' className={`menu-item item-header px-3 py-2 d-flex align-items-center bg-dark mb-3 ${openDrawer ? '' : 'justify-content-center'}`}>
                            {
                                openDrawer ?
                                    <img width="125px" src={justnaikFull} alt='logo-full' />
                                    :
                                    <img width="30px" src={logoInverse} alt='logo-inverse' />
                            }
                        </Link>
                        <div className='offset-div' />
                        {
                            sideBarMenu.map(({ title, Icon, path, sub }, key) => (
                             (title != 'Custom Report' && title != 'History Log') && user.userType === 'APAD'  ?  null : 
                                (
                                <div key={key} >
                                    {
                                        sub ?
                                            <Accordion defaultActiveKey="0" >
                                                <Card className='bg-dark' >
                                                    <Accordion.Toggle className={`menu-item menu-link px-3 d-flex align-items-center ${openDrawer ? '' : 'justify-content-center'}`} as={Card.Header} eventKey="0">
                                                        <Icon size={20} className={`${currrentLoc === path ? 'text-primary' : 'text-light'}`} />
                                                        {
                                                            openDrawer &&
                                                            <h6 className='title' >{title}</h6>
                                                        }
                                                    </Accordion.Toggle>
                                                    <Accordion.Collapse eventKey="0">
                                                        <Card.Body className='p-0' >
                                                            {
                                                                sub.map(({ title: t, path: p, Icon: I }, key2) => (
                                                                    t == 'Bus Transfer' && !user?.agency?.isApadReport  ?  null : 
                                                                    (
                                                                    <Link key={key2} to={p} className={`menu-item menu-link d-flex align-items-center ${openDrawer ? 'pl-5' : 'justify-content-center'}`} >
                                                                        <I size={20} className={`${currrentLoc === p ? 'text-primary' : 'text-light'}`} />
                                                                        {
                                                                            openDrawer &&
                                                                            <h6 className='title' >{t}</h6>
                                                                        }
                                                                    </Link>
                                                                ))
                                                                )
                                                            }
                                                        </Card.Body>
                                                    </Accordion.Collapse>
                                                </Card>
                                            </Accordion>
                                            :
                                            <Link key={key} to={path} className={`menu-item menu-link px-3 d-flex align-items-center ${openDrawer ? '' : 'justify-content-center'}`} >
                                                <Icon size={20} className={`${currrentLoc === path ? 'text-primary' : 'text-light'}`} />
                                                {
                                                    openDrawer &&
                                                    <h6 className='title' >{title}</h6>
                                                }
                                            </Link>
                                    }
                                </div>

                            ))
                            )
                        }
                        <div className='offset-div' />
                    </div>
                </Nav>
            </div>
            <main onMouseEnter={handleCloseDrawer} className='main bg-light' >
                {children}
            </main>
        </div>
    )
}

export default Sidebar
