import { useCallback, useMemo, useState } from "react";
import { Alert, Card, Col, Form, Row, Button, FormControl } from 'react-bootstrap'
import { toast } from 'react-toastify'
import { ContentWrapper, SimpleInputGroup, Table, Modal, ErrorModal } from "../../components";
import { CloudUpload, InfoCircle } from 'react-bootstrap-icons'
import { ApiRequest, exportData } from '../../helpers'
import { API_END_POINTS } from '../../config'
import moment from 'moment'
import { CircularProgress } from "@material-ui/core";
import { LambdaClient, InvokeCommand } from "@aws-sdk/client-lambda"; // ES Modules import
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";

const config = {
    region: 'ap-southeast-1',
    credentials: {
        accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
        secretAccessKey: process.env.REACT_APP_AWS_ACCESS_SECRET
    },
};

// Create a Lambda client
const client = new LambdaClient(config);

const TransactionQuery = ({ user, onSubmitQuery = () => {}, onClearQuery = () => {} }) => {
    const [route, setRoute] = useState('All');
    const [amPm, setAmPm] = useState('All');
    const [weekendWeekday, setWeekendWeekday] = useState('All');
    const [selectFromDate, setFromDate] = useState(null);
    const [selectToDate, setToDate] = useState(null);
    const [vehicle, setVehicle] = useState(null);
    const [driver, setDriver] = useState(null);

    const [canFilter, setCanFilter] = useState(false);

    const handleSubmitQuery = (e) => {
        e.preventDefault();
        
        const query = { route, selectFromDate, selectToDate }

        if (!selectFromDate || selectFromDate === 'null') return toast.error("Please select the month to search");
        if (!route || route === 'null') return toast.error("Please select a route");
        onSubmitQuery(query)
    }

    const handleClear = () => {
        document.getElementById('date-from').value = ''
        setFromDate(null)
        setToDate(null)
        setRoute(null)
        setWeekendWeekday('All')
        onClearQuery()
    }

    const setperiod = async (e) => {
        const mon = e.currentTarget.value;
        const start = await moment(mon)
          .startOf("month")
          .format("YYYY-MM-DD 00:00:00");
        const end = await moment(mon).endOf("month").format("YYYY-MM-DD 23:59:59");
        await setFromDate(start);
        setToDate(end);
        setCanFilter(true);
    };
    
    return (
        <Card>
            <Card.Body >
                <h5>Search</h5>
                <Row>
                    <Col lg={4} md={12} sm={12} ></Col>
                    <Col lg={8} md={12} sm={12} >
                        <Form onSubmit={handleSubmitQuery} className='text-right' >
                            <SimpleInputGroup labelWidth={10} preappendText="Month:">
                                <Form.Control
                                    id="date-from"
                                    max={moment().format("YYYY-MM")}
                                    onChange={setperiod}
                                    min={
                                        user.userType !== "SUPER_ADMIN"
                                        ? moment().subtract(3, "months").format("YYYY-MM")
                                        : moment().subtract(12, "months").format("YYYY-MM")
                                    }
                                    type="month"
                                />
                            </SimpleInputGroup>
                            <Button className='ml-2' onClick={handleClear} variant='warning' >Clear</Button>
                            <Button className='ml-2' type='submit' >Search</Button>
                        </Form>
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    )
}

const MyBasMonthlySummaryPage = ({ user }) => {
    const [loading, setLoading] = useState(false);
    const [transaction, setTransaction] = useState(null);
    const [mainApiData, setMainApiData] = useState(null);
    const [monthYear, setMonthYear] = useState("");
    const [errorModal, setErrorModal] = useState(false);

    const handleFilterTransactionData = useCallback( async ({ route, selectFromDate, selectToDate, }) => {
        setLoading(true);
        setMonthYear(moment(selectFromDate).format("MMMM YYYY").toUpperCase());

        if (process.env.REACT_APP_NODE_ENV != 'production') {
            console.log('local data')
            const data = await ApiRequest.fetch({
                method: "get",
                url: `${API_END_POINTS.SBST_MONTHLY_COLLECTION}`,
                params: {
                    timestamp: new Date(),
                    from: selectFromDate,
                    to: selectToDate,
                    routeShortName: route
                },
            });

            setMainApiData(data);
            setLoading(false);
        } else {
            // console.log('productino data');

            try {
                const data = await ApiRequest.fetch({
                    method: "post",
                    url: `${API_END_POINTS.LAMBDA_QUEUE_START}`,
                    data: {
                        timestamp: new Date(),
                        from: selectFromDate,
                        to: selectToDate,
                        routeShortName: route,
                        agencyId: user.agency.id,
                        reportType: 'sbstMonthlySummary'
                    },
                });

                let { jobId, key, mode } = data;

                if (mode === 'CACHE') {
                    try {
                        const data = await ApiRequest.fetch({
                            method: "get",
                            url: `${API_END_POINTS.LAMBDA_REPORT}/${key}`,
                        });

                        setMainApiData(data.returnData);
                        setLoading(false);
                    } catch(fetchError) {
                        console.error("Error fetching cached data:", fetchError);
                        setLoading(false);
                        setErrorModal(true);
                    }
                } else {
                    const jobStatusInterval = setInterval(async () => {
                        // console.log('checking status');
                        try {
                            const statusResponse = await ApiRequest.fetch({
                                method: "get",
                                url: `${API_END_POINTS.LAMBDA_QUEUE_FIND}`,
                                params: { jobId },
                            });
                            
                            if (statusResponse.status === 'COMPLETED') {
                                // implement download file from S3
                                const data = await ApiRequest.fetch({
                                    method: "get",
                                    url: `${API_END_POINTS.LAMBDA_REPORT}/${key}`,
                                });

                                console.log('data: ', data);

                                clearInterval(jobStatusInterval); // Stop polling
                                setMainApiData(data.returnData); // Use completed data
                                setLoading(false);
                            } else if (statusResponse.status === 'FAILED') {
                                clearInterval(jobStatusInterval); // Stop polling
                                setLoading(false);
                                setErrorModal(true);
                            }
                        } catch (pollingError) {
                            console.error("Error checking job status:", pollingError);
                            setLoading(false);
                            setErrorModal(true);
                        }
                    }, 5000);
                }
            } catch(error) {
                console.error('Error invoking lambda: ', error);
                setLoading(false);
                setErrorModal(true);
            }
        }
    }, [transaction]);

    const generateExportData = async () => {
        if (!mainApiData || mainApiData.length == 0) {
            return toast.warning('Nothing to export!');
        }

        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet("Report");
    
        // Define header sections with colors matching the image
        const headerSections = [
            // Shift all column references one letter to the right (A->B, B->C, etc.)
            { start: "E5", end: "I5", title: "KM", bgColor: "9cc2e5", color: "000000" }, // Blue section
            { start: "J5", end: "J5", title: "Blank", bgColor: "9cc2e5", color: "9cc2e5" }, // Empty blue section
            { start: "K5", end: "L5", title: "Trips", bgColor: "ffc001", color: "000000" }, // Yellow section
            { start: "M5", end: "X5", title: "Penalty", bgColor: "f4b083", color: "000000" }, // Orange section for penalty
        ];
    
        // Add metadata at the top
        worksheet.addRow([`FINAL CLAIM FOR ${monthYear}`]).font = { bold: true, size: 14 };
        worksheet.addRow([]);
        worksheet.addRow([`Network Operator: ${user.agency.name}`]).font = { bold: true, size: 14 };
        worksheet.addRow(["Network Area: "]).font = { bold: true, size: 14 };
        worksheet.addRow([]);
    
        // Merge and style header sections
        headerSections.forEach((section) => {
            if (section.title) {
                worksheet.mergeCells(section.start + ":" + section.end);
                const cell = worksheet.getCell(section.start);
                cell.value = section.title;
                cell.font = { bold: true, color: { argb: section.color.replace("#", "") } };
                cell.fill = {
                    type: "pattern",
                    pattern: "solid",
                    fgColor: { argb: section.bgColor.replace("#", "") }
                };
                cell.alignment = { horizontal: "center", vertical: "middle", wrapText: true };
                cell.border = {
                    top: { style: "thin" },
                    left: { style: "thin" },
                    bottom: { style: "thin" },
                    right: { style: "thin" }
                };
            }
        });
    
        // Map your table headers to Excel columns
        const headers = [
            { header: "Route No", key: "routeShortName", bgColor: "a8d08d", width: 12 },
            { header: "OD", key: "routeName", bgColor: "a8d08d", width: 30 },
            { header: "Total km per trip\n(as per BOP)", key: "kmPerTrip", bgColor: "a8d08d", width: 15 },
            { header: "Total KM Planned", key: "totalPlannedKm_", bgColor: "9cc2e5", width: 15 },
            { header: "Total KM Service Served (ETM)", key: "totalMileage_", bgColor: "9cc2e5", width: 20 },
            { header: "Total Claim (RM) (ETM)", key: "totalClaim_", bgColor: "9cc2e5", width: 20 },
            { header: "Total KM Service Served (Verified)", key: "totalKmServiceServedVerified", bgColor: "9cc2e5", width: 25 },
            { header: "Total Claim (RM) (Verified)", key: "totalClaimVerified", bgColor: "9cc2e5", width: 20 },
            { header: "Operator Charge\n(Total Claim / Total KM)", key: "operatorCharge", bgColor: "9cc2e5", width: 15 },
            { header: "Trips Planned", key: "totalTripCount_", bgColor: "ffc001", width: 12 },
            { header: "Trips Made", key: "completedTripCount_", bgColor: "ffc001", width: 12 },
            { header: "No of Trips Missed", key: "offRouteCount_", bgColor: "f4b083", width: 15 },
            { header: "Percentage (%)\nNo of trips served", key: "servedTripPercentage_", bgColor: "f4b083", width: 15 },
            { header: "Weightage Applicable", key: "tripWeightage", bgColor: "f4b083", width: 15 },
            { header: "Amount (RM)", key: "tripPenaltyAmount", bgColor: "f4b083", width: 12 },
            { header: "No of Trips Observed\n(Late/Early)", key: "lateEarlyDeparture_", bgColor: "f4b083", width: 15 },
            { header: "Percentage (%)\nPunctuality", key: "punctualityPercentage_", bgColor: "f4b083", width: 15 },
            { header: "Weightage Applicable", key: "punctualityWeightage", bgColor: "f4b083", width: 15 },
            { header: "Amount (RM)", key: "punctualityPenaltyAmount", bgColor: "f4b083", width: 12 },
            { header: "No of Breakdowns", key: "tripBreakdown_", bgColor: "f4b083", width: 15 },
            { header: "Weightage Applicable", key: "breakdownWeightage", bgColor: "f4b083", width: 15 },
            { header: "Amount (RM)", key: "breakdownPenaltyAmount", bgColor: "f4b083", width: 12 },
            { header: "Grand Total of Penalty (RM)", key: "grandTotalPenalty", bgColor: "f4b083", width: 20 },
        ];

        // Style the header row (Row 6)
        const headerRow = worksheet.getRow(6);
        headers.forEach((header, index) => {
            const cell = headerRow.getCell(index + 2);

            cell.value = header.header;
            cell.font = { bold: true, size: 11, color: { argb: "000000" } };

            cell.alignment = { horizontal: "center", vertical: "middle", wrapText: true };

            cell.fill = {
                type: "pattern",
                pattern: "solid",
                fgColor: { argb: header.bgColor.replace("#", "") }
            };
            cell.border = {
                top: { style: "thin" },
                left: { style: "thin" },
                bottom: { style: "thin" },
                right: { style: "thin" }
            };
        });
    
        // Set row height for headers
        worksheet.getRow(5).height = 30;
        worksheet.getRow(6).height = 50;
    
        // Define the start row for data (after headers)
        const dataStartRow = 7;
        const dataEndRow = dataStartRow + mainApiData.length;

        // Add data rows
        mainApiData.push({
            routeShortName: '',
            routeName: '',
            kmPerTrip: '',
            totalPlannedKm_: '',
            totalMileage_: '', // need to chang).toFixed(2)e
            totalClaim_: '',
            totalKmServiceServedVerified: '',
            totalClaimVerified: '',
            totalTripCount_: '',
            completedTripCount_: '',
            tripPenaltyAmount: '',
            punctualityPenaltyAmount: '',
            offRouteCount_: '',
            lateEarlyDeparture_: '',
            tripBreakdown_: '',
            breakdownPenaltyAmount: '',
            grandTotalPenalty: '',
        });

        mainApiData.forEach((row) => {
            const rowData = headers.map(header => row[header.key] || "");
            const dataRow = worksheet.addRow(['', ...rowData]); // Add empty cell at start for TRUNK column
            
            // Apply column-wise alignment starting from column B
            dataRow.eachCell((cell, colIndex) => {
                if (colIndex === 1) return; // Skip TRUNK column
    
                let horizontalAlignValue;
                const adjustedColIndex = colIndex - 1; // Adjust index for shifted columns
                
                if (adjustedColIndex === 1 || adjustedColIndex === 3) {
                    horizontalAlignValue = "center";  // Route No, Total KM Per Trip
                } else if (adjustedColIndex === 2) {
                    horizontalAlignValue = "left";  // OD (Route Name)
                } else {
                    horizontalAlignValue = "right";  // Everything else
                }
        
                cell.alignment = { vertical: "middle", horizontal: horizontalAlignValue };
        
                cell.border = {
                    top: { style: "thin" },
                    left: { style: "thin" },
                    bottom: { style: "thin" },
                    right: { style: "thin" }
                };
            });
        });

        // calculate grand total
        const grandTotalKMPerTrip = mainApiData.reduce((sum, data) => sum + Number(data.kmPerTrip || 0), 0);
        const grandTotalKMPlanned = mainApiData.reduce((sum, data) => sum + Number(data.totalPlannedKm_ || 0), 0);
        const grandTotalKMServed = mainApiData.reduce((sum, data) => sum + Number(data.totalMileage_ || 0), 0);
        const grandTotalClaim = mainApiData.reduce((sum, data) => sum + Number(data.totalClaim_ || 0), 0);
        const grandTotalKMServedVerified = mainApiData.reduce((sum, data) => sum + Number(data.totalKmServiceServedVerified || 0), 0);
        const grandTotalClaimVerified = mainApiData.reduce((sum, data) => sum + Number(data.totalClaimVerified || 0), 0);
        const grandTotalTripsPlanned = mainApiData.reduce((sum, data) => sum + Number(data.totalTripCount_ || 0), 0);
        const grandTotalTripsMade = mainApiData.reduce((sum, data) => sum + Number(data.completedTripCount_ || 0), 0);
        const grandTotalTripsMissed = mainApiData.reduce((sum, data) => sum + Number(data.offRouteCount_ || 0), 0);
        const grandTotalTripPenalty = mainApiData.reduce((sum, data) => sum + Number(data.tripPenaltyAmount || 0), 0);
        const grandTotalTripsLateEarly = mainApiData.reduce((sum, data) => sum + Number(data.lateEarlyDeparture_ || 0), 0);
        const grandTotalPunctualityPenalty = mainApiData.reduce((sum, data) => sum + Number(data.punctualityPenaltyAmount || 0), 0);
        const grandTotalBreakdown = mainApiData.reduce((sum, data) => sum + Number(data.tripBreakdown_ || 0), 0);
        const grandTotalBreakdownPenalty = mainApiData.reduce((sum, data) => sum + Number(data.breakdownPenaltyAmount || 0), 0);
        const grandTotalPenalty = mainApiData.reduce((sum, data) => sum + Number(data.grandTotalPenalty || 0), 0);

        // grand total row
        worksheet.addRow([
            "GRAND TOTAL", "", "", grandTotalKMPerTrip, grandTotalKMPlanned, grandTotalKMServed, grandTotalClaim, grandTotalKMServedVerified, grandTotalClaimVerified, "", 
            grandTotalTripsPlanned, grandTotalTripsMade, 
            grandTotalTripsMissed, "", "", grandTotalTripPenalty,
            grandTotalTripsLateEarly, "", "", grandTotalPunctualityPenalty,
            grandTotalBreakdown, "", grandTotalBreakdownPenalty, grandTotalPenalty
        ]);

        const grandTotalStartRow = dataEndRow + 1;
        worksheet.mergeCells(`A${grandTotalStartRow}:C${grandTotalStartRow}`);
        const grandTotalRow = worksheet.getRow(grandTotalStartRow);

        grandTotalRow.height = 20;
        grandTotalRow.alignment = { vertical: "middle" };
        grandTotalRow.font = { bold: true, size: 11, color: { argb: "000000" } };
        grandTotalRow.border = {
            top: { style: "thin" },
            left: { style: "thin" },
            bottom: { style: "thin" },
            right: { style: "thin" }
        };

        const grandTotalCell = worksheet.getCell(`A${grandTotalStartRow}`);
        grandTotalCell.alignment = { vertical: "middle", horizontal: "center" };

        // Add and merge TRUNK cell
        worksheet.mergeCells(`A${dataStartRow}:A${dataEndRow}`);
        const trunkCell = worksheet.getCell(`A${dataStartRow}`);
        trunkCell.value = "TRUNK";
        trunkCell.font = { bold: true };
        trunkCell.alignment = { vertical: "middle", horizontal: "center" };
        trunkCell.border = {
            top: { style: "thin" },
            left: { style: "thin" },
            bottom: { style: "thin" },
            right: { style: "thin" }
        };

        // Adjust column widths
        worksheet.columns.forEach((column, index) => {
            if (index === 0) {
                column.width = 10; // Width for TRUNK column
            } else if (index == 2) {
                column.width = 30;
            } else {
                column.width = column.width || 15;
            }
        });
    
        // Create Excel file and trigger download
        const buffer = await workbook.xlsx.writeBuffer();
        const data = new Blob([buffer], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
    
        const filename = `SBST_Monthly_Summary_${moment(monthYear).format("MMMM-YYYY")}.xlsx`;
        saveAs(data, filename);
    };

    // Table Columns
    const tableHeader = useMemo(() => [
        {
            Header: "Route No",
            accessor: "routeShortName",
            // disableFilters: true
        },
        {
            Header: "OD",
            accessor: "routeName",
            disableFilters: true
        },
        {
            Header: "Total KM Per Trip (as per BOP)",
            accessor: "kmPerTrip",
            disableFilters: true
        },
        {
            Header: "Total KM Planned",
            accessor: "totalPlannedKm_",
            disableFilters: true
        },
        {
            Header: "Total KM Service Served (ETM)",
            accessor: "totalMileage_",
            disableFilters: true
        },
        {
            Header: "Total Claim (RM) (ETM)",
            accessor: "totalClaim_",
            disableFilters: true
        },
        {
            Header: "Total KM Service Served (Verifiied)",
            accessor: "totalKmServiceServedVerified",
            disableFilters: true
        },
        {
            Header: "Total Claim (RM) (Verifiied)",
            accessor: "totalClaimVerified",
            disableFilters: true
        },
        {
            Header: "Operator Charge (Total Claim / Total KM)",
            accessor: "operatorCharge",
            disableFilters: true
        },
        {
            Header: "Trips Planned",
            accessor: "totalTripCount_",
            disableFilters: true
        },
        {
            Header: "Trips Made",
            accessor: "completedTripCount_",
            disableFilters: true
        },
        {
            Header: "No of Trips Missed",
            accessor: "offRouteCount_",
            disableFilters: true
        },
        {
            Header: "Percentage (%) No of trips served",
            accessor: "servedTripPercentage_",
            disableFilters: true
        },
        {
            Header: "Weightage Applicable",
            accessor: "tripWeightage",
            disableFilters: true
        },
        {
            Header: "Amount",
            accessor: "tripPenaltyAmount",
            disableFilters: true
        },
        {
            Header: "No of Trips Observed (Late / Early)",
            accessor: "lateEarlyDeparture_",
            disableFilters: true
        },
        {
            Header: "Percentage (%) Punctuality",
            accessor: "punctualityPercentage_",
            disableFilters: true
        },
        {
            Header: "Weightage Applicable",
            accessor: "punctualityWeightage",
            disableFilters: true
        },
        {
            Header: "Amount",
            accessor: "punctualityPenaltyAmount",
            disableFilters: true
        },
        {
            Header: "No of Breakdowns",
            accessor: "tripBreakdown_",
            disableFilters: true
        },
        {
            Header: "Weightage Applicable",
            accessor: "breakdownWeightage",
            disableFilters: true
        },
        {
            Header: "Amount",
            accessor: "breakdownPenaltyAmount",
            disableFilters: true
        },
        {
            Header: "Grand Total of Penalty (RM)",
            accessor: "grandTotalPenalty",
            disableFilters: true
        },
    ]);

    const AlertSBSTNotAllowed = () => {
        return(
            <div>
                <Alert variant='info' >
                    <Alert.Heading>Report Not Enabled</Alert.Heading>
                    <p>
                        This report is not activated / allowed on your agency. <br/>
                        Please contact {" "}
                        <a href="mailto: info@justnaik.com">info@justnaik.com</a> to use this feature.
                    </p>
                </Alert>
            </div>
        );
    };

    const MyBasBody = () => {
        return(
            <div>
                <TransactionQuery
                    onSubmitQuery={handleFilterTransactionData}
                    user={user}
                />
                    
                <Alert className="mt-3" variant="info">
                    <InfoCircle /> SBST Report only shows data for the past 1
                    months - Contact{" "}
                    <a href="mailto: info@justnaik.com">info@justnaik.com</a> for
                    more
                </Alert>

                 <div className='w-100 text-right' >
                    <Button onClick={() => generateExportData()}>Export Data</Button>
                </div>

                {
                    mainApiData ?
                    <Table numbering columns={tableHeader} data={mainApiData} />
                    :
                    <Table numbering columns={tableHeader} data={[]} />
                }
            </div>
        );
    };

    return(
        <div className="p-3">
            <h2 className='mb-3' >MyBas Monthly Summary Report</h2>
            <Card className='mt-3' >
            <Card.Body>
                    {                   
                        user.agency.isAllowSbst ? <MyBasBody /> : <AlertSBSTNotAllowed />
                    }
                </Card.Body>
            </Card>

            <Modal size="sm" centered show={loading}>
                <div className="text-center">
                <CircularProgress size={70} />
                </div>
            </Modal>

            <ErrorModal showError={errorModal} handleClose={() => setErrorModal(false)} />
        </div>
    );
}

export default MyBasMonthlySummaryPage;