import { connect } from "react-redux";
import MyBasMonthlySummaryPage from "./MyBasMonthlySummaryPage";
const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

export default connect(
    mapStateToProps,
    {}
) (MyBasMonthlySummaryPage)